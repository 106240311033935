<template>
  <div class="form-div">
    <div><Checkbox v-model="isCheck" @on-change="changeCheck">特殊困难群体信息采集</Checkbox></div>
    <Menu v-show="isCheck" mode="horizontal" theme="light" :active-name="activeName" style="height:50px" @on-select="selectMenu">
      <MenuItem name="1">
            基本内容
        </MenuItem>
      <MenuItem v-for="(item,index) in treeDataList" :key="index" :name="String(index+2)" >
            {{item.itemName}}
        </MenuItem>
    </Menu>
     
     <Form style="margin-top:10px" v-show="isCheck && activeName == '1' " class="isForm" label-position="right" :label-width="170">
        <FormItem class="isNoWidth">
          <span slot="label">档案编号:</span>
          <Input placeholder="请输入档案编号"  v-model="formDataHard.hardNo" />
        </FormItem>
        <!-- <FormItem class="isWidth">
          <span slot="label">所属社区:</span>
          <Cascader
                v-model="formDataHard.orgCodeSelf"
                :data="publishList"
                :load-data="loadCommunity"
                change-on-select
                :disabled="checkOther"
              ></Cascader>
        </FormItem> -->
        <FormItem class="isNoWidth">
             <span slot="label"><Checkbox class="checkbox" v-model="checkOther" @on-change="changeOther"></Checkbox>所属其他:</span>
            <Input class="input" v-if="checkOther" v-model="formDataHard.orgCodeOther"/>
        </FormItem>
        <FormItem class="isNoWidth">
          <span slot="label">分类:</span>
          <RadioGroup v-model="formDataHard.hardType">
                 <Radio v-for="(item,index) in hardType" :key="index" :label="item.dictKey">
                     {{item.dictValue}}
                 </Radio>    
           </RadioGroup> 
        </FormItem>
        <FormItem class="isNoWidth" v-if="formDataHard.hardType == 8">
            <span slot="label" class="validate">其他:</span>
          <Input class="input" placeholder="请填写其他" v-model="formDataHard.hardTypeOther"/>
        </FormItem>
        <FormItem class="isNoWidth">
            <span slot="label">括号内为分值，当前总分:</span>
            <span>{{formDataHard.score || 0}}</span>
        </FormItem>
        <FormItem class="isNoWidth">
            <span slot="label">分级:</span>
            <RadioGroup v-model="formDataHard.scoreLevel" >
                 <Radio v-for="(item,index) in scoreLevel" disabled="true" :key="index" :label="item.dictKey">
                     {{item.dictValue}}
                 </Radio>    
           </RadioGroup>
        </FormItem>
        <FormItem class="isNoWidth">
            <span slot="label">使用社会服务记录:</span>
            <RadioGroup v-model="formDataHard.socialService" :disabled="true">
                 <Radio label="1">无</Radio>
                 <Radio label="2">曾有（请备注服务及时间）</Radio>
           </RadioGroup>
        </FormItem>
        <FormItem class="isNoWidth" v-if="formDataHard.socialService == 2">
            <span slot="label" class="validate">曾有社会服务记录:</span>
            <Input  placeholder="请填写曾有社会服务记录" v-model="formDataHard.socialServiceDesc" type="textarea"/>
        </FormItem>
     </Form>
     <div v-show="isCheck && activeName == index+2 "  v-for="(item,index) in treeDataList" :key="index+2">
         <Form style="margin-top:10px" class="isForm" label-position="right" :label-width="260" v-for="(items,indexs) in   item.children" :key="indexs">
          <div class="title">{{items.itemName}}</div>
              <FormItem class="isNoWidth" v-for="(itemss,indexss) in items.children" :key="indexss">
                    <span slot="label">{{itemss.itemName}}:</span>
                    <RadioGroup v-for="(itemsss,indexsss) in itemss.children" :key="indexsss" v-model="itemss.groupItem" @on-change="changeRadio">
                      <Radio class="radio-item" :label="itemsss.itemId">{{itemsss.itemName}} ({{itemsss.itemValue}})</Radio>
                    </RadioGroup>
                </FormItem>
          </Form>
          <div class="title" v-if="activeName == 2 || activeName == 3">其他方面</div>
          <Form style="margin-top:10px" class="isForm" label-position="right" :label-width="100">
            <FormItem class="isNoWidth" v-if="activeName == 2">
                  <span slot="label">存在的问题:</span>
                   <Input class="input" v-model="formDataHard.familyQuestion"/>
              </FormItem>
              <FormItem class="isNoWidth" v-if="activeName == 2">
                  <span slot="label">本人的需求:</span>
                   <Input class="input" v-model="formDataHard.familyRequirement"/>
              </FormItem> 
              <FormItem class="isNoWidth" v-if="activeName == 3">
                  <span slot="label">本人的需求:</span>
                   <Input class="input" v-model="formDataHard.selfRequirement"/>
              </FormItem> 
          </Form>
     </div>
     <!-- <Form style="margin-top:10px" v-show="isCheck && activeName == '2' " class="isForm" label-position="right" :label-width="150" v-for="(item,index) in treeDataList[0].children" :key="index">
       <div class="title">{{item.itemName}}</div>
       <FormItem class="isNoWidth" v-for="(items,indexs) in item.children" :key="indexs">
            <span slot="label">{{items.itemName}}:</span>
            
        </FormItem>

     </Form> -->
  </div>
</template>

<script>
export default {
  props:{
    specialCheck:{
      type:Boolean,
      default:()=>{
        return false
      }
    },
    formDataHard:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    userId:{
            type:Object,
            default:()=>{
                return ''
            }
    },
    archiveHardItemRoList:{
          type:Array,
          default:()=>{
            return []
          }
    },
    checkOther:{
          type:Boolean,
          default:()=>{
            return false
          }
    },
    
  },
  data(){
    return{
      isCheck:false,
      publishList: [],//所属社区
      // checkOther:false,
      hardType:[
         {dictKey:'1',dictValue:'低保对象'},
         {dictKey:'2',dictValue:'低收入对象'},
         {dictKey:'3',dictValue:'特困人员'},
         {dictKey:'4',dictValue:'留守老年人'},
         {dictKey:'5',dictValue:'孤独孤寡老人'},
         {dictKey:'6',dictValue:'困境儿童'},
         {dictKey:'7',dictValue:'贫困重度残疾人'},
         {dictKey:'8',dictValue:'其他'},
      ],
      scoreLevel:[
        {dictKey:'1',dictValue:'一级（155-200）'},
        {dictKey:'2',dictValue:'二级（105-150）'},
        {dictKey:'3',dictValue:'三级（55-100）'},
        {dictKey:'4',dictValue:'四级（0-50）'},
      ],
      activeName:'1',
      treeDataList:[]
    }
  },
  async created(){

   await this.getTree()
   await this.getDetail()
    if(this.specialCheck == true){
      this.isCheck = true
    }
  },
  methods:{ 
    changeOther(val){
      this.$emit('changeOther',val)
    },
    changeRadio(){
      this.$emit('treeDataList',this.treeDataList)
    },
    selectMenu(name){
           this.activeName = name
     },
    changeCheck(val){
      this.isCheck = val
      this.$emit('changeSpecial',val)
    },
   async getTree(){
     await this.$get('/syaa/api/syuser/pc/residentArchive/getArchiveHardTree').then(res=>{
        // this.treeDataList = res.dataList
        this.treeDataList = this.formatterTree(res.dataList)
      })
    },
    formatterTree(val){
      val.map(item =>{
        item.itemCode = item.itemCode.split('-')
        if(item.itemCode.length ==3 && item.itemCode.length != 4){
          item.groupItem = ''
          item.groupScore = ''
        }else{
          if(item.children && item.children.length !=0 && item.children != null)
          this.formatterTree(item.children)
        }
      })
      return val
    },

        async getDetail(){
          if(this.userId && this.userId != ''){
           await this.$get('/syaa/api/syuser/pc/residentArchive/selectArchiveHard',{
              userId:this.userId,
              // familyId:this.familyId
            }).then(res=>{
              if(res.code == 200){
                 let data = {}
                  data = res.data
                  this.$emit('getSpecially',data)
                  console.log('详情里边的树结构',this.treeDataList);
                  this.$emit('getTreeData',this.treeDataList)
                  // console.log('详情里边的树结构',this.treeDataList );
              }else{
                this.$Message.error({
                  content:'特殊困难群体信息采集获取失败',
                  background:true
                })
                return
              }
            })
          }
          
        }
  },
   watch:{
     specialCheck:{
       hanlder(val,newVal){
         if(val){
           this.isCheck = val
         }
       }
     }
   }
}
</script>

<style lang="less" scoped>
.form-div{
    width:100%;
    user-select: none;
    .isForm{
        display: flex;
        flex-wrap: wrap;
        .isWidth{
        width: 40%;
        margin-right: 40px;
        }
        .isNoWidth{
            width: 100%;
        }
    }
    
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
/deep/.ivu-date-picker{
    display: inline;
}
.wai-div{
  display: block;

}
.title{
  width: 100%;
    font-size: 18px;
    font-weight: bold;
}
.radio-item{
  margin-right: 20px;
}
</style>